<template>
    <div>
        <div class="demo-inline-spacing align-right" style="justify-content: flex-end;" v-if="holidayListDates">
            <span> Attendance for </span>
            <feather-icon
                icon="CalendarIcon"
                size="16"
            />
            <div>
            <flat-pickr
                v-model="dateSelected"
                :config="flatpickrConfig"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="Select Date"
            />
            </div>
        </div>
        <b-row class="match-height" v-if="attendance && totalEmp">
            <b-col
                lg="3"
                cols="3"
            >
                <statistic-card-horizontal
                    icon="UsersIcon"
                    color="info"
                    :statistic="totalEmp"
                    statistic-title="Total Employees"
                />
            </b-col>
            <b-col
                lg="3"
                cols="3"
            >
            <statistic-card-horizontal
                    icon="UsersPlusIcon"
                    color="success"
                    :statistic="presentEmp"
                    statistic-title="Present"
                />
            </b-col>
            <b-col
                lg="3"
                cols="3"
            >
            <statistic-card-horizontal
                    icon="UsersPlusIcon"
                    color="warning"
                    :statistic="halfDayEmp"
                    statistic-title="Half-Day"
                />
            </b-col>
            <b-col
                lg="3"
                cols="3"
            >
            <statistic-card-horizontal
                    icon="UsersMinusIcon"
                    color="danger"
                    :statistic="absentEmp"
                    statistic-title="Absent"
                />
            </b-col>
        </b-row>
        <!-- <div v-else-if="isTodayWeekend"><span class="font-weight-bolder text-danger">Its a Weekend</span></div>    
        <div v-else-if="isHoliday"><span class="font-weight-bolder text-danger"> Its a Holiday</span></div> -->
        <div v-else><span class="font-weight-bolder text-danger"> No Attendance Marked for Today</span></div>
        <b-card>
            <div v-if="loading">
                <div class="text-center">
                  <b-spinner variant="primary" label="Text Centered" />
                </div>
            </div>
            <div v-if="!loading && attendance ">
              <div class="mb-1">
              <b-row>
                <b-col col>
                    <span> Date Selected: <strong class="text-primary">{{ dateSelected }} </strong></span>
                </b-col>
                <b-col col>
                    <b-button
                        size="sm"
                        class="mr-1"
                        variant="outline-primary"
                        @click="selectAllRows"
                    >
                    Select all
                    </b-button>
                </b-col>
                
                <b-col col>
                    <v-select
                        style="width: 200px;"
                        v-model="statusSelected"
                        placeholder="Select Status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="statusOptions"
                        ref="status"
                    />
                </b-col>
                <b-col col v-if="this.selected.length > 0">
                  <b-button
                    size="sm"
                    class="mr-1"
                    
                    variant="primary"
                    @click="bulkUpdate()"
                >
                 Submit
                </b-button>
                </b-col>
                
                <!-- <b-button
                  size="sm"
                  class="mr-1"
                  variant="outline-warning"
                  @click="refreshData()"
                >
                 Refresh
                </b-button> -->
                <b-col col v-if="this.selected.length > 0">
                    <b-button
                    size="sm"
                    class="mr-1"
                    
                    variant="outline-secondary"
                    @click="clearSelected"
                    >
                    Clear selected
                    </b-button>
                </b-col>
                
                <b-col col>
                    <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                <b-input-group size="sm">
                    <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                    trim
                    />
                    <b-input-group-append>
                    <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                    >
                        Clear
                    </b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
                </b-col>
            </b-row>
            
                
              </div>
              <b-table
                ref="selectableTable"
                selectable
                select-mode="multi"
                :fields="fields"
                :items="attendance"
                responsive="sm"
                :filter="filter"
                @filtered="onFiltered"
                @row-selected="onRowSelected"
              >
                <!-- Example scoped slot for select state illustrative purposes -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
                  
                </template>
                
                <template #cell(emp_id)="data">
                  <div class="text-nowrap">
                    {{ data.item.emp_id }}

                  </div>
                </template>

                <template #cell(name)="data">
                  <div class="text-nowrap">
                    {{ data.item.name }}
                  </div>
                </template>
                <template #cell(doj)="data">
                  <div class="text-nowrap">
                    {{ data.item.doj }}
                  </div>
                </template>
                <template #cell(location)="data">
                  <div class="text-nowrap">
                    {{ data.item.location }}
                  </div>
                </template>
                <template #cell(attendance)="data">
                  <div class="text-nowrap">
                    <b-badge pill variant="light-primary" v-if="data.item.attendance === 'present'">
                        {{ data.item.attendance }}
                    </b-badge>
                    <b-badge pill variant="light-warning" v-else-if="data.item.attendance === 'half-day'">
                        {{ data.item.attendance }}
                    </b-badge>
                    <b-badge pill variant="light-danger" v-else-if="data.item.attendance === 'leave'">
                        {{ data.item.attendance }}
                    </b-badge>
                    <b-badge pill variant="light-secondary" v-else>
                        {{ data.item.attendance }}
                    </b-badge>
                    
                  </div>
                </template>
                <template #cell(remarks)="data">
                  <div class="text-nowrap">
                    {{ data.item.remarks }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap demo-inline-spacing" >
                    <!-- <v-select
                        style="width: 200px; margin-top: 0 !important;"
                        v-model="statusSelectedSingle"
                        placeholder="Select Status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="statusOptions"
                        ref="status"
                    /> -->
                    <b-button
                      size="sm"
                      class="mr-1"
                      variant="primary"
                      @click="updateSingle(data.item)"
                      style="margin-top: 0 !important;"
                    >
                      Update
                    </b-button>
                  </div>
                </template>
              </b-table>

             
              
            </div>
            
            <div v-if="!loading && !attendance ">
              {{  reportStatus }}
            </div>  
        </b-card>

        <!--  -->
                
    </div>
</template>    
<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { BRow, BCol, BButton, BCard, BBadge, BSpinner, BTable, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import moment from 'moment'
import AttendanceService from '@/services/attendance-service'
import AdminConfigService from '@/services/admin-config-service'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import attendanceService from '@/services/attendance-service'

export default {
    name: 'Attendance',
    components: {
        vSelect,
        flatPickr,
        StatisticCardHorizontal,
        BRow, BCol, BButton, BCard, BBadge, BSpinner, BTable, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend
    },
    data() {
        return {
            dateSelected: null, // selected date ranges for attendance report generation
            // flatpickrConfig: {
                
            // },
            holidayList: null,
            holidayListDates: null,
            flatpickrConfig: {
                onChange: this.handleDateChange,
                disable: this.holidayListDates
                // disable: [
                // // function(date) {
                // //     // Disable Saturdays and Sundays
                // //     return date.getDay() === 6 || date.getDay() === 0;
                // // },
                // '2024-01-26',
                // '2024-03-25',
                // '2024-04-11',
                // '2024-05-01',
                // '2024-08-15',
                // '2024-08-19',
                // '2024-10-02',
                // '2024-11-01',
                // '2024-12-25',
                // ],
                // disable: this.holidayListDates ? this.holidayListDates : [
                // function(date) {
                //     // Disable Saturdays and Sundays
                //     return date.getDay() === 6 || date.getDay() === 0;
                // },
                // '2024-01-26',
                // '2024-03-25',
                // '2024-04-11',
                // '2024-05-01',
                // // '2024-08-15',
                // '2024-08-19',
                // '2024-10-02',
                // '2024-11-01',
                // '2024-12-25',
                // ]
            },
            attendance: [],
            loading: true,
            fields: ['selected', { key: 'emp_id', label: 'Emp Id',sortable: true, }, { key: 'name', label: 'Employee Name',sortable: true, },{ key: 'doj', label: 'DOJ',sortable: true, },{ key: 'location', label: 'Location',sortable: true, }, { key: 'attendance', label: 'Attendance' }, { key: 'leave_type', label: 'Remarks' },  {key: 'actions'}],
            selected: [],
            hasAccess: false,
            isTodayWeekend: false,
            isHoliday: false,
            reportStatus: null,
            statusOptions: [{
                title:'Present', abbrev: 'P', value: 0
            }],
            statusSelected: null,
            totalEmp: 0,
            presentEmp: 0,
            halfDayEmp: 0,
            absentEmp: 0,
            filter : null
            
        }
    },
    created() {
        const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
        this.userEmpId = JSON.parse(localStorage.getItem('userData')).emp_id

        this.hasAccess = userRole === 'ACCOUNT-MANAGER' || userRole === 'TALENT-ADVISOR' || userRole === 'TALENT-ADVISOR-SOURCER' || userRole === 'PROGRAM-MANAGER' ? false : true
        console.log(this.hasAccess)
        if(this.hasAccess === true) {
            //this.postDate = moment().locale("en").add(-1, 'days').format("YYYY-MM-DD")
            this.dateSelected = moment().locale("en").format("YYYY-MM-DD")
            this.loading = true

            AdminConfigService.getConfig('attendance','status').then(res => {
                if (res.status === 'OK') {
                    console.log(res.data)
                    this.statusOptions = res.data
                } else if ((res.status === 401 ) || (res.status === 403 )) {
                    window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                    localStorage.clear(); 
                    this.$router.push({ name: "auth-login" });
                } else if (res.status === 404 ) { 
                    alert("Data Not Found, some error occurred: " + res.status + '\n' + JSON.parse(JSON.stringify(res.data)) + '\n' + res.statusText);
                } else {
                    alert("Error fetching data: " + JSON.parse((JSON.stringify(res))));
                }
            })

            this.getAttendanceOnDate(this.dateSelected)
        } else {
            this.$router.push({ name: "misc-not-authorized" });
        }
    },
    methods: {
        getAttendanceOnDate(date) {
            this.loading = true
            this.attendance = null
            AttendanceService.getAttendanceOnDate(date).then(res => {
                if (res.status === 'OK') {
                    
                    console.log('Employee LIST: ', res)
                    this.attendance = res.data
                    this.totalEmp = res.data.length
                    this.presentEmp = this.attendance.filter(item => item.attendance === 'present').length
                    this.halfDayEmp = this.attendance.filter(item => item.attendance === 'half-day').length
                    this.absentEmp = this.attendance.filter(item => item.attendance === 'leave').length
                    this.loading = false


                } else if ((res.status === 401 ) || (res.status === 403 )) {
                    this.loading = false
                    window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                    localStorage.clear(); 
                    this.$router.push({ name: "auth-login" });
                } else if (res.status === 404 ) { 
                    alert("Data Not Found, some error occurred: " + res.status + '\n' + JSON.parse(JSON.stringify(res.data)) + '\n' + res.statusText);
                } else {
                    alert("Error fetching data: " + JSON.parse((JSON.stringify(res))));
                }
            })
        },
        onRowSelected(items) {
        this.selected = items
        console.log(this.selected)
        },
        selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
        console.log(this.selected)
        },
        clearSelected() {
        this.$refs.selectableTable.clearSelected()
        },
        handleDateChange() {
            
            console.log('Selected date:',this.dateSelected);
            
            this.getAttendanceOnDate(this.dateSelected)
        },
        updateSingle(item) {
            if(!this.statusSelected) {
                alert("Please Select Attendance Status!!!!")
                return
            }
            // this.userEmpId = JSON.parse(localStorage.getItem('userData')).emp_id
            const status = JSON.parse(JSON.stringify(this.statusSelected))
            console.log('Status Selected',status, item)
            let data = {
                emp_id: item.emp_id,
                date: this.dateSelected,
                mgr_id: item.mgr_id,
                attendance: status.title === 'Present' || status.title === 'Work From Home' ? 'present' : status.title.includes('Half') ? 'half-day' : status.title.includes(' Off') || status.title.includes('Leave') ? 'leave' : 'holiday',
                count: status.value,
                leave_type: status.title,
                remarks: status.abbrev,
                leave_count: status.title === 'Present' || status.title === 'Work From Home' ? 0 : status.title.includes('Half') ? 0.5 : status.title.includes(' Off') || status.title.includes('Leave') ? 1 : 0,
                // added_by: this.userEmpId,
                updated_by: this.userEmpId

            }
            
            console.log('linkedinpost data: ',data,item)
            this.loading = true
            this.updatePost(item.id,data,'single')
            
            this.loading = false
        },
        bulkUpdate() {
            this.loading = true
            this.postSubmitted = true
            const status = JSON.parse(JSON.stringify(this.statusSelected))
            for (var i = 0; i < this.selected.length; i++) {
                const id = this.selected[i].id
                let data = {
                    emp_id: this.selected[i].emp_id,
                    date: this.dateSelected,
                    attendance: status.title === 'Present' || status.title === 'Work From Home' ? 'present' : status.title.includes('Half') ? 'half-day' : status.title.includes('Leave') ? 'leave' : 'holiday',
                    count: status.value,
                    leave_type: status.title,
                    remarks: status.abbrev,
                    mgr_id: this.selected[i].mgr_id,
                    // added_by: this.userEmpId,
                    leave_count: status.title === 'Present' || status.title === 'Work From Home' ? 0 : status.title.includes('Half') ? 0.5 : status.title.includes(' Off') || status.title.includes('Leave') ? 1 : 0,
                    updated_by: this.userEmpId
                }
                console.log(data, this.selected[i])
                this.updatePost(id,data,'bulk');
            }
            this.getAttendanceOnDate(this.dateSelected)
            this.loading = false
        },
        updatePost(id,data,type) {
            console.log('update data', id, data)
            AttendanceService.update(id,data).then(res => {
                if (res.status === 'OK') {
                    console.log(res.data)
                    if(type === 'single') {
                      this.getAttendanceOnDate(this.dateSelected)
                    }
                    
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: "Attendance updated",
                        icon: "EditIcon",
                        variant: "success",
                        timeout: 3000,
                        },
                    });
                    

                } else if ((res.status === 401 ) || (res.status === 403 )) {
                    this.loading = false
                    window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                    localStorage.clear(); 
                    this.$router.push({ name: "auth-login" });
                } else if (res.status === 404 ) { 
                    alert("Data Not Found, some error occurred: " + res.status + '\n' + JSON.parse(JSON.stringify(res.data)) + '\n' + res.statusText);
                } else {
                    alert("Error fetching data: " + JSON.parse((JSON.stringify(res))));
                }
            })
            if(type === 'bulk') {
                this.getAttendanceOnDate(this.dateSelected)
            }
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        //this.totalRows = filteredItems.length
        //this.currentPage = 1
      },
      
      
    },
    beforeMount() {
        attendanceService.getHolidayList().then(res => {
            if(res.status === 200) {
                this.holidayList = res.data.data
                this.holidayListDates = this.holidayList.map(item =>  item.date)
                this.flatpickrConfig.disable = this.holidayListDates
                console.log('Holiday List: ',this.holidayListDates)
            }
        })
    }
}

</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>